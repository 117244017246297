import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Img.css';

interface LanguageProps {
    language: string;
}


function Img({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className="content-images">
                    <h1>Unsere Erinnerungsfotos</h1>
                    <p>Hier werden wir unsere Erinnerungsfotos euch zeigen.</p>
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className="content-images">
                    <h1>Our images to remember</h1>
                    <p>Here we will show you our memory photos.</p>
                </div>
                <Footer />
            </div>
        );
    }
}



export default Img;
