import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MenuGer.css';
import HLogo from '../../heart_logo/h_logo';
import FlagLogo, { Language }  from '../../flag_logo/flag_logo';


const MenuGer: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const location = useLocation();
    const switchLanguagePath = (currentPath: string) => {
        if (currentPath === "/") {
            return "/eng/home";
        }
        return currentPath.replace('/ger/', '/eng/');
    };

    return (
        <nav className="menu">
            <div className="menu-toggle flex-parent-element" onClick={toggleMenu}>
                <div className={isMenuOpen ? 'hamburger open' : 'hamburger'}></div>
            </div>
            <div className={isMenuOpen ? "menu-items active" : "menu-items"} >
                <ul>
                    <li className='menu-text'><Link to="/ger/story">Unsere Geschichte</Link></li>
                    <li className='menu-text'><Link to="/ger/best">Hochzeitsteam</Link></li>
                    <li className='menu-text'><Link to="/ger/registry">Wunschliste</Link></li>
                    <li className='menu-text'><Link to="/ger/venue">Veranstaltungsort</Link></li>
                    <li>
                        <Link to="/ger/home">
                            <div className='logo-style'>
                                <HLogo/>
                            </div>
                        </Link>
                    </li>
                    <li className='menu-text'><Link to="/ger/travel">Reise & Aufenthalt</Link></li>
                    <li className='menu-text'><Link to="/ger/details">Hochzeitsdetails</Link></li>
                    <li className='menu-text'><Link to="/ger/images">Erinnerung in Bildern</Link></li>
                    <li>
                        <Link to={switchLanguagePath(location.pathname)}>
                            <FlagLogo language={Language.English} />
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default MenuGer;
