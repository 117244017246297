import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Ven.css';

interface LanguageProps {
    language: string;
}


function Ven({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className='top-placeholder-ven'></div>
                <div className="content-top-ven-div">
                    <p>
                        Wir freuen uns sehr, euch ins <em className='weingut'>WEINGUT LISZT</em> einzuladen, <br />
                        einem Ort, der uns sehr am Herzen liegt. <br />
                        Mit seiner Mischung aus moderner und traditioneller Architektur,
                        die uns an die Whisky-Destillerie erinnert, <br />
                        die wir in Schottland besucht haben, und seiner intimen, <br />
                        einladenden Atmosphäre, könnten wir uns keinen perfekteren Ort vorstellen, 
                        um unser 'Ja, ich will' zu sagen.
                    </p>
                </div>
                <table className='content-table'>
                    <tr>
                        <th>Veranstaltungsort</th>
                        <td>Weingut Liszt</td>
                    </tr>
                    <tr>
                        <th>Adresse</th>
                        <td>Hauptstraße 10, 2443 Leithaprodersdorf</td>
                    </tr>
                    <tr>
                        <th>Datum</th>
                        <td>25. Mai 2024</td>
                    </tr>
                    <tr>
                        <th>Zeit für die Trauung</th>
                        <td>14 Uhr</td>
                    </tr>
                </table>
                <div className="content-ven-left">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d916.018019777036!2d16.475555598012534!3d47.936824323698445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c4c0cd470e367%3A0x5c232695983f2e0f!2sLISZT%20Weingut%20Heuriger%20Manufaktur!5e0!3m2!1sde!2sat!4v1709495498635!5m2!1sde!2sat&zoom=12"
                        title="Location Map for Weingut Liszt German"
                        className="iframe-map-left"
                        loading="lazy" 
                        aria-hidden="false"
                    />
                </div>
                <div className="content-ven-right">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!4v1709494586772!6m8!1m7!1snPqZzzE_pMHiaflXjksq8Q!2m2!1d47.93666841305883!2d16.47513952357278!3f348.01654!4f0!5f0.7820865974627469" 
                        title="Second Location Map for Weingut Liszt German"
                        className="iframe-map-right"
                        loading="lazy" 
                        aria-hidden="false"
                    />
                </div>
                <div className='top-placeholder-ven'></div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className='top-placeholder-ven'></div>
                <div className="content-top-ven-div">
                    <p>
                        We are excited to invite you to join us at <em className='weingut'>WEINGUT LISZT</em>, <br />
                        a place that holds a special place in our hearts. <br />
                        With its blend of modern and traditional architecture, 
                        reminiscent of the whisky distillery we visited in Scotland, <br />
                        and its intimate, welcoming atmosphere, we couldn't think of a more perfect place to say 'I do'.
                    </p>
                </div>
                <table className='content-table'>
                    <tr>
                        <th>Venue Name</th>
                        <td>Weingut Liszt</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>Hauptstraße 10, 2443 Leithaprodersdorf</td>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <td>May 25th 2024</td>
                    </tr>
                    <tr>
                        <th>Ceremony Time</th>
                        <td>2 pm</td>
                    </tr>
                </table>
                <div className="content-ven-left">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d916.018019777036!2d16.475555598012534!3d47.936824323698445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c4c0cd470e367%3A0x5c232695983f2e0f!2sLISZT%20Weingut%20Heuriger%20Manufaktur!5e0!3m2!1sde!2sat!4v1709495498635!5m2!1sde!2sat&zoom=12"
                        title="Location Map for Weingut Liszt English"
                        className="iframe-map-left"
                        loading="lazy" 
                        aria-hidden="false"
                    />
                </div>
                <div className="content-ven-right">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!4v1709494586772!6m8!1m7!1snPqZzzE_pMHiaflXjksq8Q!2m2!1d47.93666841305883!2d16.47513952357278!3f348.01654!4f0!5f0.7820865974627469" 
                        title="Second Location Map for Weingut Liszt English"
                        className="iframe-map-right"
                        loading="lazy" 
                        aria-hidden="false"
                    />
                </div>
                <div className='top-placeholder-ven'></div>
                <Footer />
            </div>
        );
    }
}



export default Ven;

