import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './components/font/font.css';
import MenuEng from './components/menu/menu_eng/MenuEng';
import Footer from './components/footer/Footer';
import './App.css';

import Home from './pages/0_home/Home';
import Story from './pages/1_story/Story';
import Best from './pages/2_best/Best';
import Reg from './pages/3_registry/Reg';
import Ven from './pages/4_venue/Ven';
import Travel from './pages/5_travel/Travel';
import Det from './pages/6_details/Det';
import Img from './pages/7_images/Img';

import NotFound from './pages/not_found/NotFound';

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <MenuEng />
        </header>
        <Footer />
        <Routes>

            <Route path="/" element={<Home language='ger' />} />
            <Route path="/wedding-website" element={<Home language='ger' />} />
            <Route path="/home" element={<Home language='ger' />} />
            <Route path="/eng/home" element={<Home language='eng' />} />
            <Route path="/ger/home" element={<Home language='ger' />} />


            <Route path="/story" element={<Story language='ger' />} />
            <Route path="/eng/story" element={<Story language='eng' />} />
            <Route path="/ger/story" element={<Story language='ger' />} />

            <Route path="/best" element={<Best language='ger' />} />
            <Route path="/eng/best" element={<Best language='eng' />} />
            <Route path="/ger/best" element={<Best language='ger' />} />

            <Route path="/registry" element={<Reg language='ger' />} />
            <Route path="/eng/registry" element={<Reg language='eng' />} />
            <Route path="/ger/registry" element={<Reg language='ger' />} />

            <Route path="/venue" element={<Ven language='ger' />} />
            <Route path="/eng/venue" element={<Ven language='eng' />} />
            <Route path="/ger/venue" element={<Ven language='ger' />} />

            <Route path="/travel" element={<Travel language='ger' />} />
            <Route path="/eng/travel" element={<Travel language='eng' />} />
            <Route path="/ger/travel" element={<Travel language='ger' />} />

            <Route path="/details" element={<Det language='ger' />} />
            <Route path="/eng/details" element={<Det language='eng' />} />
            <Route path="/ger/details" element={<Det language='ger' />} />

            <Route path="/images" element={<Img language='ger' />} />
            <Route path="/eng/images" element={<Img language='eng' />} />
            <Route path="/ger/images" element={<Img language='ger' />} />

            <Route path="*" element={<NotFound language="ger" />} />
            <Route path="/ger/*" element={<NotFound language="ger" />} />
            <Route path="/eng/*" element={<NotFound language="eng" />} />




        </Routes>
      </Router>
    </div>
  );
}

export default App;
