import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Story.css';

interface LanguageProps {
    language: string;
}


function Story({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className="content-story">
                    <h1 className='content-story-h1'>Unsere Geschichte</h1>
                    <div className="row">
                        <div className="column">
                            <div className='content-div'>
                                <p className='text-left text-title'>Zwischen 2005 and 2009</p>
                                <p className='text-left'>Lisa sieht Stefan beim Skateboarden und traut sich nicht ihn anzusprechen.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>24. Mai 2011</p>
                                <p className='text-left'> 
                                    <img src="./data/2011-IMG_9321.jpg" alt="" /> 
                                    Lisa und Stefan starten ihre Beziehung.
                                </p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>März 2012</p>
                                <p className='text-left'>Erster Urlaub als Paar in Thailand.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>Sommer 2017</p>
                                <p className='text-left'>Erstes Mal Backpacking durch Europa.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>Februar 2019</p>
                                <p className='text-left'>Umzug zurück nach Wien.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>25. Mai 2024</p>
                                <p className='text-left'>Lisa und Stefan heiraten.</p>
                            </div>
                        </div>
                        <div className="column column-middle">
                            <p className='vertical-line'></p>
                        </div>
                        <div className="column column-right">
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>Irgendwann 2010</p>
                                <p className='text-right'>Lisa und Stefan treffen sich das erste Mal in der Tanzschule.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>Juli 2011</p>
                                <p className='text-right'>Louis wird geboren.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>September 2014</p>
                                <p className='text-right'>Erste gemeinsame Wohnung in Graz.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>Dezember 2018</p>
                                <p className='text-right'>Erste Reise nach Schottland.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>27. Dezember 2022</p>
                                <p className='text-right'>Lisa und Stefan verloben sich am seals beach in Schottland.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className="content-story">
                    <h1 className='content-story-h1'>Our story</h1>
                    <div className="row">
                        <div className="column">
                            <div className='content-div'>
                                <p className='text-left text-title'>Sometime between 2005 and 2009</p>
                                <p className='text-left'>Lisa sees Stefan skateboarding and is scared to join.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>24th May 2011</p>
                                <p className='text-left'> 
                                    <img src="./data/2011-IMG_9321.jpg" alt="" /> 
                                    Lisa and Stefan decided to start their relationship.
                                </p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>March 2012</p>
                                <p className='text-left'>The first trip together as a couple to thailand.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>Summer 2017</p>
                                <p className='text-left'>First backpacking trip through europe.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>February 2019</p>
                                <p className='text-left'>The move back to Vienna.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'></p>
                                <p className='text-left'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-left text-title'>25th May 2024</p>
                                <p className='text-left'>Lisa and Stefan get married.</p>
                            </div>
                        </div>
                        <div className="column column-middle">
                            <p className='vertical-line'></p>
                        </div>
                        <div className="column column-right">
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>Sometime in 2010</p>
                                <p className='text-right'>Lisa and Stefan meet the first time in dancing school.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>July 2011</p>
                                <p className='text-right'>Louis is born.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>September 2014</p>
                                <p className='text-right'>Lisa and Stefan move together and to Graz.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>December 2018</p>
                                <p className='text-right'>First time in Scottland.</p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'></p>
                                <p className='text-right'></p>
                            </div>
                            <div className='content-div'>
                                <p className='text-right text-title'>27th December 2022</p>
                                <p className='text-right'>Lisa and Stefan get engaged on the seals beach in Scotland.</p>
                            </div>
                        </div>
                    </div>
                    <div className='placeholder'></div>

                </div>
                <Footer />
            </div>
        );
    }
}



export default Story;
