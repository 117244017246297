// This file defines the flag logo component.

import React from 'react';
import './flag_logo.css';
import austria_flag from './austria_flag.png';
import uk_flag from './uk_flag.png';

export enum Language {
    German = 'German',
    English = 'English'
}

interface FlagLogoProps {
    language: Language;
}

const FlagLogo: React.FC<FlagLogoProps> = ({ language }) => {
    let flagImage, altText;

    switch (language) {
        case Language.German:
            flagImage = austria_flag;
            altText = 'Austria Flag';
            break;
        case Language.English:
            flagImage = uk_flag;
            altText = 'UK Flag';
            break;
        default:
            flagImage = null;
            altText = 'No Flag';
    }

    return (
        <div className="flag-logo-div">
            {flagImage && <img src={flagImage} alt={altText} className="land-flag" />}
        </div>
    );
};

export default FlagLogo;
