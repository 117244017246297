import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Reg.css';
import DanceImage from './dance_foto_c.jpg';

interface LanguageProps {
    language: string;
}


function Reg({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className='column-def-reg'>
                    <div className='column'>
                        <img src={DanceImage} alt="" className='dance-image-reg'/>
                    </div>
                    <div className='column'>
                        <div className="content-reg">
                            <p>
                                Liebe Freunde und Familie, wir freuen uns sehr, unseren besonderen Tag mit euch zu teilen!
                                Während wir uns auf diese wunderschöne gemeinsame Lebensreise begeben, 
                                ist es einer unserer Träume, mit unvergesslichen Flitterwochen zu starten. 
                                Wir träumen davon, unsere Reise mit einer  Kreuzfahrt in die Karibik zu beginnen.
                            </p>
                            <p>
                            Anstelle traditioneller Hochzeitsgeschenke laden wir diejenigen, die etwas spenden möchten, herzlich dazu ein, einen Beitrag zu unserem Fonds für die Flitterwochen Kreuzfahrt zu leisten. Jedes Geschenk trägt dazu bei, unser maritimes Abenteuer zu etwas Besonderem zu machen und einzigartige Erinnerungen zu schaffen, die ein Leben lang anhalten.
                            </p>
                            <p>
                            Für diejenigen, die einen Beitrag leisten möchten, haben wir am Hochzeitsort eine spezielle „Flitterwochen-Fondsbox“ zusammengestellt.
        Wenn ihr möchtet, könnt ihr euer Geschenk, versiegelt in einem Umschlag, in die Schachtel legen.
        Bitte beachtet, dass der Betrag, den ihr uns gebt, ganz bei euch liegt – jeder Beitrag, egal wie groß oder klein, wird sehr geschätzt.
                            </p>
                            <p>
                            Euer Beitrag ist mehr als nur ein Geschenk – er ist eine Investition in wertvolle Erinnerungen, die uns für immer in Erinnerung bleiben werden. Jedes Mal, wenn wir den Sonnenaufgang von unserem Schiff aus beobachten, ein romantisches Abendessen vor der Kulisse des Ozeans genießen oder eine neue Karibikinsel betreten, werden wir uns daran erinnern, dass dies ein Moment ist, der durch die Liebe und Großzügigkeit unserer Lieben ermöglicht wurde .
                            </p>
                            <p>
                            Wir möchten unseren tiefsten Dank dafür zum Ausdruck bringen, dass ihr einen Beitrag zu unserem Flitterwochenfonds in Betracht gezogen haben. Über den Geldwert hinaus sind es die Liebe und der Gedanke dahinter, die uns wirklich bewegen. Wir können es kaum erwarten, unsere schönen Erlebnisse und Bilder mit euch zu teilen!
                            </p>
                        </div>
                    </div>
                    <div className='reg-placeholder-end'></div>

                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className='column-def-reg'>
                    <div className='column'>
                        <img src={DanceImage} alt="" className='dance-image-reg'/>
                    </div>
                    <div className='column'>
                        <div className="content-reg">
                            <p>
                                Dear friends and family, we are overjoyed to share our special day with you!
                                As we embark on this beautiful journey of life together, one of our dreams is to start with an unforgettable honeymoon. 
                                We dream of starting our journey with an unforgettable cruise to the Caribbean.
                            </p>
                            <p>
                                Instead of traditional wedding gifts, we kindly invite those who wish to give to contribute to our dream honeymoon cruise fund. 
                                Each gift will help make our maritime adventure more special and will contribute to creating memories that will last a lifetime.
                            </p>
                            <p>
                            We have arranged a special 'Honeymoon Fund Box' at the wedding venue for those who wish to contribute.
                                If you feel comfortable, you may place your gift, sealed in an envelope, into the box.
                                Please know that the amount you give is completely up to you - every contribution, no matter how large or small, is greatly appreciated.
                            </p>
                            <p>
                            Your contribution is more than just a gift - it's an investment in cherished memories that we will hold dear forever. Every time we watch the sun rise from our ship, enjoy a romantic dinner against the backdrop of the ocean, or step foot on a new Caribbean island, we'll remember that it's a moment made possible by the love and generosity of our dear ones.
                            </p>
                            <p>
                            We want to express our deepest gratitude for considering a contribution to our honeymoon fund. Beyond the monetary value, it's the love and thought behind it that truly moves us. We can't wait to share our beautiful experiences and pictures with you!
                            </p>
                        </div>
                    </div>
                    <div className='reg-placeholder-end'></div>

                </div>
                <Footer />
            </div>
        );
    }
}



export default Reg;
