import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Best.css';

interface LanguageProps {
    language: string;
}


function Best({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className="content-best">
                    <h1 className='title-best'>Hochzeitsteam</h1>
                    <div className='best-mate'>
                        <p className='best-mate-title'>Nicole</p>
                        <p className='best-mate-text'>
                            Nicole, meine Donnerstagsfreundin und beste Freundin seit der Schulzeit, 
                            teilt mit mir eine besondere Verbindung, eine Freundschaft, die keine soziale Batterie benötigt, 
                            mit einer Vielzahl an gemeinsamen Interessen und der geteilten Liebe für Schottland.
                            Wir erzählen uns alles - ein Zeichen wahrer Freundschaft und tiefen Vertrauens.
                        </p>
                    </div>
                    <div className='best-row'>
                        <div className='best-column'>
                            <p className='best-mate-title'>Carina</p>
                            <p className='best-texts'>
                                Carina, meine Arbeitskollegin und erste RiAA-Freundin.
                                Vor allem die gemeinsame Hochzeitsplanung hat uns eine besondere Verbindung zueinander aufbauen lassen 
                                und diese Erfahrungen haben uns unzertrennlich gemacht.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Christina</p>
                            <p className='best-texts'>
                                Christina, meine Schulfreundin mit dem fast gleichen Berufsweg, ist ein fester Bestandteil meines Lebens. 
                                Unsere fast täglichen Telefonate nach der Arbeit sind mehr als nur Gespräche über den Tag; sie sind ein Austausch von Seelen.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Isabella</p>
                            <p className='best-texts'>
                                Isabella, als eine Hälfte unseres besten Freundespaars, ist seit Jahren Teil unserer Reise. 
                                Unsere Abende mit Brettspielen, Essen und tiefgründigen Gesprächen über das Leben sind geschätzte Erinnerungen. 
                                Wir teilen die Motivation für Zumba und bewahren die Erinnerung an unvergessliche Camps.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Yvonne</p>
                            <p className='best-texts'>
                                Yvonne, meine Studienfreundin, die auch von Graz nach Wien gezogen ist, war stets eine Quelle der Inspiration. 
                                Die gegenseitige Motivation im Studium und die gemeinsamen akademischen Kämpfe haben uns beide vorangebracht, so ist 
                                eine einzigartige Freundschaft entstanden.
                            </p>
                        </div>
                    </div>
                    <div className='best-placeholder'></div>

                    <div className='best-mate'>
                        <p className='best-mate-title'>Victoria</p>
                        <p className='best-mate-text'>
                            Victoria als meine Trauzeugin zu haben, spiegelt unsere außergewöhnliche Bindung wider. 
                            Die schwierigsten Zeiten bei der Arbeit gemeinsam zu überstehen, hat unser Verständnis und unsere Wertschätzung füreinander nur vertieft.
                        </p>
                    </div>

                    <div className='best-row best-row-man'> 
                        <div className='best-column'>
                            <p className='best-mate-title'>Levi</p>
                            <p className='best-texts'>
                                Levi, mein ältester Freund seit der ersten Klasse Gymnasium, 
                                war ein ständiger Begleiter in meinem Leben, mit unzähligen gemeinsamen Erinnerungen und Meilensteinen.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Mattias</p>
                            <p className='best-texts'>
                                Mattias, als eine hälfte unseres besten Freundespaar, ist seit Jahren Teil unserer Reise. 
                                Unsere Abende mit Brettspielen, Essen und tiefgründigen Gesprächen über das Leben sind geschätzte Erinnerungen.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Mario</p>
                            <p className='best-texts'>
                                Mario, mein bester Studienfreund, war in den härtesten Zeiten an der Universität für mich da. 
                                Die unzähligen Stunden, die wir gemeinsam mit dem Studium verbracht haben, haben eine Freundschaft gefestigt, die bleibt.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Nadine</p>
                            <p className='best-texts'>
                                Nadine, meine beste Studienfreundin, stand in guten wie in schlechten Zeiten an meiner Seite. 
                                Unsere gemeinsamen akademischen Kämpfe und Erfolge haben eine einzigartige Freundschaft geschmiedet, die bleibt.
                            </p>
                        </div>
                    </div>
                    <div className='best-placeholder-end'></div>

                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className="content-best">
                    <h1 className='title-best'>Weddingteam</h1>
                    <div className='best-mate'>
                        <p className='best-mate-title'>Nicole</p>
                        <p className='best-mate-text'>
                            Nicole, my Thursday friend and best friend since our school days, 
                            shares a special bond with me, a friendship that doesn't require a social battery, 
                            with a plethora of shared interests and a mutual love for Scotland. 
                            We tell each other everything - a sign of true friendship and deep trust.
                        </p>
                    </div>
                    <div className='best-row'>
                        <div className='best-column'>
                            <p className='best-mate-title'>Carina</p>
                            <p className='best-texts'>
                                Carina, my work colleague and first RiAA friend. 
                                Above all, planning a wedding together has allowed us to build a special connection, 
                                and these experiences have made us inseparable.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Christina</p>
                            <p className='best-texts'>
                                Christina, my school friend with almost the same career path, is an integral part of my life. 
                                Our almost daily phone calls after work are more than just conversations about the day; they are an exchange of souls.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Isabella</p>
                            <p className='best-texts'>
                                Isabella, our closest couple's friend, has been part of our journey for years. 
                                Our evenings with board games, dining, and deep conversations about life are treasured memories. 
                                We share a motivation for Zumba and preserve the memory of unforgettable camps.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Yvonne</p>
                            <p className='best-texts'>
                                Yvonne, my study friend who also moved from Graz to Vienna, has always been a source of inspiration. 
                                The mutual motivation during our studies and our shared academic struggles have propelled us both forward, 
                                creating a unique friendship in the process.
                            </p>
                        </div>
                    </div>

                    <div className='best-placeholder'></div>

                    <div className='best-mate'>
                        <p className='best-mate-title'>Victoria</p>
                        <p className='best-mate-text'>Having Victoria as my best woman reflects our extraordinary bond. Surviving the toughest times at work together has only deepened our understanding and appreciation of each other.</p>
                    </div>

                    <div className='best-row best-row-man'> 
                        <div className='best-column'>
                            <p className='best-mate-title'>Levi</p>
                            <p className='best-texts'>
                                Levi, my oldest friend since the first grade of high school, 
                                has been a constant in my life, sharing countless memories and milestones together.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Mattias</p>
                            <p className='best-texts'>
                                Mattias, our closest couple's friend, has been part of our journey for years. 
                                Our evenings filled with board games, food, and deep conversations about life are treasured memories.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Mario</p>
                            <p className='best-texts'>
                                Mario, my university comrade, was there through the toughest times at university. 
                                Our countless hours spent studying together cemented a friendship that lasts.
                            </p>
                        </div>
                        <div className='best-column'>
                            <p className='best-mate-title'>Nadine</p>
                            <p className='best-texts'>
                                Nadine, my university comrade, stood by me through thick and thin. 
                                Our shared academic battles and successes have forged a unique friendship that lasts.
                            </p>
                        </div>
                    </div>
                    <div className='best-placeholder-end'></div>

                </div>
                <Footer />
            </div>
        );
    }
}



export default Best;
