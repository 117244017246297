import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MenuEng.css';
import HLogo from '../../heart_logo/h_logo';
import FlagLogo, { Language }  from '../../flag_logo/flag_logo';


const MenuEng: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const location = useLocation();
    const switchLanguagePath = (currentPath: string) => {
        if (currentPath === "/") {
            return "/ger/home";
        }
        return currentPath.replace('/eng/', '/ger/');
    };
    return (
        <nav className="menu">
            <div className="menu-toggle flex-parent-element" onClick={toggleMenu}>
                <div className={isMenuOpen ? 'hamburger open' : 'hamburger'}></div>
            </div>
            <div className={isMenuOpen ? "menu-items active" : "menu-items"} >
                <ul>
                    <li className='menu-text'><Link to="/eng/story">The Story of our life</Link></li>
                    <li className='menu-text'><Link to="/eng/best">Wedding Team</Link></li>
                    <li className='menu-text'><Link to="/eng/registry">Registry</Link></li>
                    <li className='menu-text'><Link to="/eng/venue">Venue</Link></li>
                    <li>
                        <Link to="/eng/home">
                            <div className='logo-style'>
                                <HLogo/>
                            </div>
                        </Link>
                    </li>
                    <li className='menu-text'><Link to="/eng/travel">Travel & Stay</Link></li>
                    <li className='menu-text'><Link to="/eng/details">Wedding Details</Link></li>
                    <li className='menu-text'><Link to="/eng/images">Memories in Frames</Link></li>
                    <li className='flag'>
                        <Link to={switchLanguagePath(location.pathname)}>
                            <FlagLogo language={Language.German} />
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default MenuEng;
