import * as React from 'react';
import './Footer.css';
import heartFooterLogo from './love.png';

// const Footer: React.FC = () => {
//     return (
//         <footer className="footer">
//             <span className="footer-text footer-text-right">L</span>
//             <img src={heartFooterLogo} alt="heart logo" className="heart-footer-logo" />
//             <span className="footer-text footer-text-left">S</span>
//         </footer>
//     );
// };

// export default Footer;

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <span className="footer-text">L</span>
            <img src={heartFooterLogo} alt="heart logo" className="heart-footer-logo" />
            <span className="footer-text">S</span>
        </footer>
    );
};
export default Footer;