import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Travel.css';
import hotelImage from './hotel.jpeg';

interface LanguageProps {
    language: string;
}


function Travel({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className="content-travel">
                    <h1 className='travel-title'>Wie komme ich hin?</h1>
                    <div className='info-text'>
                        <p>
                            Die Hochzeit findet im Weingut Liszt statt.
                            Ihr könnt entweder mit dem Auto oder mit dem Zug anreisen. Wir bitten euch, wenn ihr im Hotel schläft, das Auto dort zu parken.
                            Es gibt einen Shuttleservice von uns. Wenn ihr dennoch im Weingut parken möchtet, gibt es dort auch Parkplätze.
                        </p>
                    </div>

                    <div className='row-travel'>
                        <div className='column-travel dev-border'>
                            <p className='column-title'>Hochzeit</p>
                            <div className='address-def'>
                                <p className='def-hotel-name'>Weingut Liszt</p>
                                <p>Hauptstraße 10 | 2443 Leithaprodersdorf</p>
                                <a href="www.lisztwein.at">www.lisztwein.at</a>
                            </div>
                        </div>
                        <div className='column-travel'>
                            <p className='column-title'>Hotel</p>
                            <div className='address-def'>
                                <p className='def-hotel-name'>Neufeldersee Hotel & Restaurant</p>
                                <p>Eisenstädter-Straße 35 | 2491 Neufeld an der Leitha</p>
                                <p> 
                                    <a href="https://neufelderseehotel.at">neufelderseehotel.at</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='placeholder'></div>
                    <h1 className='travel-title'>Übernachtung</h1>
                    <div className='info-text'>
                        <p>
                        Wir haben für euch Zimmer in einem Hotel reserviert. Die Kosten für die Übernachtung müsst ihr selbst tragen.
                        Bitte gebt uns bescheid, ob ihr ein Zimmer buchen möchtet und wir kümmern uns um alles.
                        </p>
                    </div>

                    <img src={hotelImage} alt="" className='hotel-image' />

                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className="content-travel">
                <h1 className='travel-title'>How to get there?</h1>
                    <div className='info-text'>
                        <p>
                            The wedding will take place at the "Weingut Liszt".
                            You can arrive either by car or by train. If you sleep in the hotel, we ask you to park your car there.
                            We have a shuttle service. If you still want to park at the winery, there are also parking spaces there.
                        </p>
                    </div>

                    <div className='row-travel'>
                        <div className='column-travel dev-border'>
                            <p className='column-title'>Wedding</p>
                            <div className='address-def'>
                                <p className='def-hotel-name'>Weingut Liszt</p>
                                <p>Hauptstraße 10 | 2443 Leithaprodersdorf</p>
                                <a href="www.lisztwein.at">www.lisztwein.at</a>
                            </div>
                        </div>
                        <div className='column-travel'>
                            <p className='column-title'>Hotel</p>
                            <div className='address-def'>
                                <p className='def-hotel-name'>Neufeldersee Hotel & Restaurant</p>
                                <p>Eisenstädter-Straße 35 | 2491 Neufeld an der Leitha</p>
                                <p> 
                                    <a href="https://neufelderseehotel.at">neufelderseehotel.at</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='placeholder'></div>
                    <h1 className='travel-title'>Overnight stay</h1>
                    <div className='info-text'>
                        <p>
                            We have reserved rooms in a hotel for you. You have to cover the costs for the overnight stay yourself.
                            Please let us know if you would like to book a room and we will take care of everything.
                        </p>
                    </div>

                    <img src={hotelImage} alt="" className='hotel-image' />
                </div>
                <Footer />
            </div>
        );
    }
}



export default Travel;
