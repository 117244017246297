// NotFound.tsx

import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';

interface NotFoundProps {
    language: string;
}


function NotFound({ language }: NotFoundProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div>
                    <h1>404 Seite nicht gefunden</h1>
                    <p>Die Seite, nach der Sie suchen, existiert leider nicht.</p>
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div>
                    <h1>404 Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </div>
                <Footer />
            </div>
        );
    }
}



export default NotFound;
