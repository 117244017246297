import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Home.css';
import ProposalImage from './proposal.jpg';

interface LanguageProps {
    language: string;
}


function Home({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className='welcome'>
                    <p>
                        Willkommen auf unserer Hochzeitsseite!
                    </p>
                </div>
                <div>
                    <img src={ProposalImage} alt="" className='proposal-image-home'/>
                </div>
                <div className="content">
                    <p>
                        Unsere gemeinsame Reise war wie das Genießen eines feinen Whiskys - voller einzigartiger Momente, 
                        reicher Erfahrungen und einem unvergesslichen Nachgeschmack. Während wir unseren Bund feiern, 
                        freuen wir uns auf all die Orte, die noch zu erkunden sind, und die schönen Erinnerungen, 
                        die noch geschaffen werden sollen.
                    </p>
                    <p>
                    Remember, always treat people with kindness.
                    </p>
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className='welcome'>
                    <p>
                        Welcome to our wedding page!
                    </p>
                </div>
                <div>
                    <img src={ProposalImage} alt="" className='proposal-image-home'/>
                </div>
                <div className="content">
                    <p>
                        Our journey together has been like savoring a fine whisky - 
                        full of unique moments, rich experiences, and an unforgettable aftertaste. As we celebrate our union,
                        we look forward to all the places yet to explore and the beautiful memories yet to create.
                    </p>
                    <p>
                    Remember, always treat people with kindness.
                    </p>
                </div>
                <Footer />
            </div>
        );
    }
}



export default Home;
