// This file defines the Lisa & Stefan logo component.

import React from 'react';
import './h_logo.css';
import heartLogo from './heart_logo.png';

const HLogo: React.FC = () => {
    return (
        <div className="heart-logo-div">
            <img src={heartLogo} alt="heart logo" className="heart-logo" />
        </div>
    );
};

export default HLogo;