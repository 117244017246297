import React from 'react';
import MenuGer from '../../components/menu/menu_ger/MenuGer';
import MenuEng from '../../components/menu/menu_eng/MenuEng';
import Footer from '../../components/footer/Footer';
import './Det.css';

import german_img from './german.png';
import english_img from './english.png';

interface LanguageProps {
    language: string;
}


function Det({ language }: LanguageProps) {
    if (language === "ger") {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuGer />
                </header>
                <div className="content-det">
                    <h1>Details über die Hochzeit</h1>
                    <h2>Ablauf</h2>
                    <img src={german_img} alt="" className='hotel-image' />

                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <MenuEng />
                </header>
                <div className="content-det">
                    <h1>Wedding details</h1>
                    <h2>Timeline</h2>
                    <img src={english_img} alt="" className='hotel-image' />

                </div>
                <Footer />
            </div>
        );
    }
}



export default Det;
